import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from 'src/app/admin-portal/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomerDashboardService {
  baseUrl = environment.apiUrl;
  searchQuery = new BehaviorSubject<any>('');
  constructor(private apiService: ApiService) {}

  emitSearchQuery(arg: String) {
    this.searchQuery.next(arg);
  }

  getDashboardData(accountId: string): Observable<any> {
    const path = `${this.baseUrl}/dashboard-customer/finance-data?accountId=${accountId}`;
    return this.apiService.get(path);
  }
}

<div class="main-content body-bg listing-wrapper">
  <div class="p-6">
    <div class="d-flex justify-between align-center mb-6">
      <div>
        <h4 class="mat-headline-5 strong text" style="margin-bottom: 0">
          Dashboard
        </h4>
        <p class="medium mat-body-1 text textXS">Manage Your Finance</p>
      </div>
    </div>
    <div class="body-bg dashboard-wrapper">
      <div class="dashboard-header-wrapper mb-4">
        <div class="row">
          <div class="col s12 m3 l3 card">
            <div class="account-info h-full">
              <div class="p-4 d-flex h-full">
                <div
                  class="icon-wrapper body-bg d-flex align-center justify-center mr-3"
                >
                  <span class="material-symbols-outlined"> chart_data </span>
                </div>
                <div class="w-full">
                  <div class="d-flex w-full justify-between">
                    <div class="mb-3">
                      <span class="d-block grey-text textS fw-500 mb-2"
                        >TOTAL OPEN POSITION</span
                      >
                    </div>
                    <div>
                      <div id="chart-line"></div>
                    </div>
                  </div>
                  <span class="d-block text fw-500 mat-headline-5 word-break">{{
                    totalOpenPosition
                  }}</span>
                  <div>
                    <span class="d-block textS fw-500 success-text">0%</span>
                    <span class="d-block grey-text textXS">this month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m3 l3 card">
            <div class="account-info h-full">
              <div class="p-4 d-flex h-full">
                <div
                  class="icon-wrapper body-bg d-flex align-center justify-center mr-3"
                >
                  <span class="material-symbols-outlined stepper-icon">
                    leaderboard
                  </span>
                </div>
                <div class="w-full">
                  <div class="d-flex w-full justify-between">
                    <div class="mb-3">
                      <span class="d-block grey-text textS fw-500 mb-2"
                        >TOTAL COST CENTER</span
                      >
                    </div>
                    <div>
                      <div id="chart-line-sec"></div>
                    </div>
                  </div>
                  <span class="d-block text fw-500 mat-headline-5 word-break">{{
                    totalCostCenter
                  }}</span>
                  <div>
                    <span class="d-block textS fw-500 success-text">0%</span>
                    <span class="d-block grey-text textXS">this month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m3 l3 card">
            <div class="account-info h-full">
              <div class="p-4 d-flex h-full">
                <div
                  class="icon-wrapper body-bg d-flex align-center justify-center mr-3"
                >
                  <mat-icon
                    class="stepper-icon"
                    fontSet="material-icons-outlined"
                    >group</mat-icon
                  >
                </div>
                <div class="w-full">
                  <div class="d-flex w-full justify-between">
                    <div class="mb-3">
                      <span class="d-block grey-text textS fw-500 mb-2"
                        >TOTAL TEAM</span
                      >
                    </div>
                    <div>
                      <div id="chart-line-tertiary"></div>
                    </div>
                  </div>
                  <span class="d-block text fw-500 mat-headline-5 word-break">{{
                    totalCoreTeam + totalProjectTeam
                  }}</span>
                  <div>
                    <span class="d-block textS fw-500 error-text">0%</span>
                    <span class="d-block grey-text textXS">this month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col s12 m3 l3 card">
            <div class="account-info h-full">
              <div class="p-4 d-flex h-full">
                <div
                  class="icon-wrapper body-bg d-flex align-center justify-center mr-3"
                >
                  <mat-icon
                    class="stepper-icon"
                    fontSet="material-icons-outlined"
                    >group</mat-icon
                  >
                </div>
                <div class="w-full">
                  <div class="d-flex w-full justify-between">
                    <div class="mb-3">
                      <span class="d-block grey-text textS fw-500 mb-2"
                        >TOTAL PROJECTS</span
                      >
                    </div>
                    <div>
                      <div id="line-tertiary"></div>
                    </div>
                  </div>
                  <span class="d-block text fw-500 mat-headline-5 word-break">{{
                    totalProject
                  }}</span>
                  <div>
                    <span class="d-block textS fw-500 error-text">0%</span>
                    <span class="d-block grey-text textXS">this month</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white customer-listing">
        <div class="table-border px-4 py-3 d-flex align-center justify-between">
          <span class="d-block text mat-subtitle-2 w-full fw-500"
            >Open Position</span
          >
          <sft-button
            [label]="'View All'"
            className="textXS"
            [color]="'primary'"
            [variant]="'default'"
            [disable]="false"
            [icon]="'home'"
            [disableRipple]="false"
            [size]="'small'"
            (buttonClick)="viewAll()"
          ></sft-button>
        </div>
        <app-loader *ngIf="loading"></app-loader>
        <div
          *ngIf="!loading && filteredOpenPositionList.length === 0"
          class="required-star"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
            font-size: 20px;
          "
        >
          No Open Positions found!
        </div>
        <div
          class="account-detail-table overflow-auto"
          *ngIf="!loading && filteredOpenPositionList.length > 0"
        >
          <table
            mat-table
            [dataSource]="filteredOpenPositionList"
            matSort
            (matSortChange)="onOpenPositionListSort($event)"
            style="cursor: pointer"
          >
            <ng-container matColumnDef="role">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="name"
              >
                Job Title
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text table-ellipse name-col"
                title="{{ element?.op_role }}"
                (click)="viewPositionDetails(element?.op_positionID)"
              >
                {{ element?.op_role | truncate : 10 || "--" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="projectName">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="account"
              >
                Project Name
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text name-col table-ellipse"
                (click)="viewPositionDetails(element?.op_positionID)"
                title=" {{ element?.ac_name || '--' }}"
              >
                {{
                  element?.ac_name
                    ? (element?.ac_name | truncate : 10)
                    : "--"
                }}
              </td>
            </ng-container>
            <ng-container matColumnDef="costCenter">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header="centerName"
                class="cost"
              >
                Cost Center
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text name-col table-ellipse"
                title=" {{ element?.cc_center_name }}"
                (click)="viewPositionDetails(element?.op_positionID)"
              >
                {{ element?.cc_center_name | truncate : 10 || "--" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="range">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header="experienceRange"
                class="cost"
              >
                Experience Range
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text name-col table-ellipse"
                title=" {{
                  getFormattedExperienceRange(element.op_experience_range)
                }}"
                (click)="viewPositionDetails(element?.op_positionID)"
              >
                {{ getFormattedExperienceRange(element.op_experience_range) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="startDate">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                class="date"
              >
                Preferred Start Date
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text"
                title=" {{
                  element?.op_start_date | date : 'dd MMM, yyyy' || '--'
                }}"
                (click)="viewPositionDetails(element?.op_positionID)"
              >
                {{ element?.op_start_date | date : "dd MMM, yyyy" || "--" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="openings">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header="noOfResource"
                class="opening"
              >
                No. of Openings
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                class="grey-text name-col table-ellipse"
                title="{{ element?.op_no_of_resource }}"
                (click)="viewPositionDetails(element?.op_positionID)"
              >
                {{ element?.op_no_of_resource }}
              </td>
            </ng-container>
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="action">Action</th>
              <td
                mat-cell
                *matCellDef="let element"
                class="text-center"
                style="cursor: pointer"
              >
                <mat-icon
                  [matMenuTriggerFor]="menuEdit"
                  (click)="setElement(element)"
                >
                  more_vert
                </mat-icon>
              </td>
              <mat-menu #menuEdit="matMenu" class="action-info">
                <button
                  *ngIf="element?.op_positionID"
                  mat-menu-item
                  (click)="viewPositionDetails(element?.op_positionID)"
                >
                  View
                </button>
              </mat-menu>
            </ng-container>
            <tr
              mat-header-row
              class="account-detail-header bg-white"
              *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
              mat-row
              class="account-detail-body"
              *matRowDef="let row; columns: displayedColumns"
            ></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
